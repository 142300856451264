// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_input__kTyuu {
  width: 320px;
  height: max-content;
  padding: 20px 30px;
  background-color: #F8F8F8;
  border-radius: 50px;
  color: var(--black);
  font-size: 20px;
}
.input_input__kTyuu::placeholder {
  color: #817E7E;
  font-size: inherit;
  font-weight: inherit;
}
.input_input__kTyuu:focus {
  outline: 1px solid var(--black);
}`, "",{"version":3,"sources":["webpack://./src/shared/UI/input/input.module.scss"],"names":[],"mappings":"AACA;EACC,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EAEA,mBAAA;EACA,eAAA;AADD;AAGC;EACC,cAAA;EACA,kBAAA;EACA,oBAAA;AADF;AAIC;EACC,+BAAA;AAFF","sourcesContent":["\n.input {\n\twidth: 320px;\n\theight: max-content;\n\tpadding: 20px 30px;\n\tbackground-color: #F8F8F8;\n\tborder-radius: 50px;\n\n\tcolor: var(--black);\n\tfont-size: 20px;\n\n\t&::placeholder {\n\t\tcolor: #817E7E;\n\t\tfont-size: inherit;\n\t\tfont-weight: inherit;\n\t}\n\n\t&:focus {\n\t\toutline: 1px solid var(--black);\n\t}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `input_input__kTyuu`
};
export default ___CSS_LOADER_EXPORT___;
