// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    width: 250px;
    height: 250px;
    perspective: 1000px;
    overflow: hidden;
    cursor: pointer;
}

.card__container {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
    transform-style: preserve-3d;
    display: grid;
    grid-template-areas: "card-container";
}

.card--open .card__container {
    transform: rotateY(180deg);
}

.card__front {
    background-color: var(--color-primary);
}

.card__inner {
    background-color: #E5E5E5;
}

.card__inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card__front,
.card__inner {
    display: grid;
    place-content: center;
    grid-area: card-container;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card__inner {
    transform: rotateY(180deg);
}

.card--solved {
    animation: opacityCard .5s forwards;
    animation-delay: .5s;
}

@keyframes opacityCard {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MemoryGame/Card/Card.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,4BAA4B;IAC5B,aAAa;IACb,qCAAqC;AACzC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;;IAEI,aAAa;IACb,qBAAqB;IACrB,yBAAyB;IACzB,WAAW;IACX,YAAY;IAEZ,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,mCAAmC;IACnC,oBAAoB;AACxB;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".card {\n    width: 250px;\n    height: 250px;\n    perspective: 1000px;\n    overflow: hidden;\n    cursor: pointer;\n}\n\n.card__container {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    transition: transform 0.4s;\n    transform-style: preserve-3d;\n    display: grid;\n    grid-template-areas: \"card-container\";\n}\n\n.card--open .card__container {\n    transform: rotateY(180deg);\n}\n\n.card__front {\n    background-color: var(--color-primary);\n}\n\n.card__inner {\n    background-color: #E5E5E5;\n}\n\n.card__inner img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.card__front,\n.card__inner {\n    display: grid;\n    place-content: center;\n    grid-area: card-container;\n    width: 100%;\n    height: 100%;\n    -webkit-backface-visibility: hidden;\n    backface-visibility: hidden;\n}\n\n.card__inner {\n    transform: rotateY(180deg);\n}\n\n.card--solved {\n    animation: opacityCard .5s forwards;\n    animation-delay: .5s;\n}\n\n@keyframes opacityCard {\n    0% {\n        transform: scale(1);\n    }\n    100% {\n        transform: scale(0);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
