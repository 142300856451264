// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    position: absolute;
    top: 73%;
    left: 50%;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    width: 685px;
    height: 371px;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    background: #F53;
    color: #fff;
    text-transform: uppercase;
    font-size: 24px;
    padding: 26px;
    display: none;
}

.modal--open {
    display: flex;
}

.modal__title {
    margin-top: 80px;
    margin-bottom: 30px;
}

.modal__btn {
    border-radius: 12px;
    background: #0083FC;
    padding: 12px 76px;
    margin-top: auto;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/MemoryGame/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,qBAAqB;IACrB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,gCAAgC;IAChC,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".modal {\n    position: absolute;\n    top: 73%;\n    left: 50%;\n    justify-items: center;\n    align-items: center;\n    flex-direction: column;\n    z-index: 10;\n    width: 685px;\n    height: 371px;\n    transform: translate(-50%, -50%);\n    border-radius: 12px;\n    background: #F53;\n    color: #fff;\n    text-transform: uppercase;\n    font-size: 24px;\n    padding: 26px;\n    display: none;\n}\n\n.modal--open {\n    display: flex;\n}\n\n.modal__title {\n    margin-top: 80px;\n    margin-bottom: 30px;\n}\n\n.modal__btn {\n    border-radius: 12px;\n    background: #0083FC;\n    padding: 12px 76px;\n    margin-top: auto;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
