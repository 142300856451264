// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Quizzes_hero__6Cc0b {
  background-color: #FFB800;
}
@media (max-width: 1440px) {
  .Quizzes_hero__6Cc0b {
    height: 480px;
  }
}
@media (max-width: 1000px) {
  .Quizzes_hero__6Cc0b {
    height: 420px;
  }
}
@media (max-width: 768px) {
  .Quizzes_hero__6Cc0b {
    height: 360px;
  }
}
@media (max-width: 480px) {
  .Quizzes_hero__6Cc0b {
    height: 320px;
  }
}
.Quizzes_hero__title__ffUjx {
  background-color: #FF7A00;
  top: 17.8571428571%;
  left: 2.7108433735%;
  transform: rotate(-13deg);
  transform-origin: center;
}
@media (max-width: 1440px) {
  .Quizzes_hero__title__ffUjx {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .Quizzes_hero__title__ffUjx {
    font-size: 22px;
  }
}
.Quizzes_hero__bg__G6wwt {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: soft-light;
}
.Quizzes_hero__ruby__JXlYo {
  right: 1.2048192771%;
  bottom: 0;
}
@media (max-width: 1440px) {
  .Quizzes_hero__ruby__JXlYo {
    width: 500px;
  }
}
@media (max-width: 768px) {
  .Quizzes_hero__ruby__JXlYo {
    width: 70%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/QuizzesPage/Quizzes.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;AAEI;EAJJ;IAKM,aAAA;EACJ;AACF;AAAI;EAPJ;IAQM,aAAA;EAGJ;AACF;AAFI;EAVJ;IAWM,aAAA;EAKJ;AACF;AAJI;EAbJ;IAcM,aAAA;EAOJ;AACF;AALI;EACI,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,wBAAA;AAOR;AALQ;EAPJ;IAQM,eAAA;EAQR;AACF;AAPQ;EAVJ;IAWM,eAAA;EAUR;AACF;AARI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,0BAAA;AAUR;AARI;EACI,oBAAA;EACA,SAAA;AAUR;AARQ;EAJJ;IAKM,YAAA;EAWR;AACF;AAVQ;EAPJ;IAQM,UAAA;EAaR;AACF","sourcesContent":[".hero {\n    background-color: #FFB800;\n    // background-image: url();\n\n    @media (max-width: 1440px) {\n      height: 480px; \n    }\n    @media (max-width: 1000px) {\n      height: 420px; \n    }\n    @media (max-width: 768px) {\n      height: 360px; \n    }\n    @media (max-width: 480px) {\n      height: 320px; \n    }\n\n    &__title {\n        background-color: #FF7A00;\n        top: calc(100 / 560 * 100%);\n        left: calc(45 / 1660 * 100%);\n        transform: rotate(-13deg);\n        transform-origin: center;\n\n        @media (max-width: 1440px) {\n          font-size: 30px; \n        }\n        @media (max-width: 768px) {\n          font-size: 22px; \n        }\n    }\n    &__bg {\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n        mix-blend-mode: soft-light;\n    }\n    &__ruby {\n        right: calc(20 / 1660 * 100%);\n        bottom: 0;\n\n        @media (max-width: 1440px) {\n          width: 500px; \n        }\n        @media (max-width: 768px) {\n          width: 70%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `Quizzes_hero__6Cc0b`,
	"hero__title": `Quizzes_hero__title__ffUjx`,
	"hero__bg": `Quizzes_hero__bg__G6wwt`,
	"hero__ruby": `Quizzes_hero__ruby__JXlYo`
};
export default ___CSS_LOADER_EXPORT___;
