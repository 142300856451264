// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag_tag__OkXwl {
  background-color: #F8F8F8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  cursor: pointer;
}
.Tag_tag__OkXwl span {
  font-family: "Saira", "sans-serif";
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .Tag_tag__OkXwl span {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .Tag_tag__OkXwl span {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Tag/Tag.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EACI,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACR;AACQ;EANJ;IAOM,eAAA;EAER;AACF;AADQ;EATJ;IAUM,eAAA;EAIR;AACF","sourcesContent":[".tag {\n    background-color: #F8F8F8;\n    border-radius: 50px;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    padding: 12px 20px;\n    cursor: pointer;\n\n    span {\n        font-family: \"Saira\", \"sans-serif\";\n        font-size: 20px;\n        font-weight: 500;\n        line-height: calc(24 / 20);\n\n        @media (max-width: 768px) {\n          font-size: 18px; \n        }\n        @media (max-width: 480px) {\n          font-size: 16px; \n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Tag_tag__OkXwl`
};
export default ___CSS_LOADER_EXPORT___;
