// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker {
    border: none;
    border-radius: 16px;
    padding: 20px;
}

.react-datepicker__input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.react-datepicker__calendar-icon {
    padding: 0 !important;
    left: auto;
    top: 24px !important;
    right: 30px !important;
}

.react-datepicker__navigation {  
    top: 22px
}

.react-datepicker__navigation--next {
    right: 22px;
}

.react-datepicker__navigation--previous {
    left: 22px;
}

.react-datepicker__header {
    background-color: transparent;
    border: none;
}

.react-datepicker__current-month {
    font-size: 20px;
    font-weight: 500;
    line-height: calc(24 / 20);
}

.react-datepicker__month {
    margin: 0;
}

.react-datepicker__day--selected {
    background-color: #91172C;
}

.react-datepicker__day--selected:hover {
    background-color: #91172C;
}

.react-datepicker__input-time-container {
    margin-bottom: 20px;
}

.react-datepicker-time__caption {
    font-size: 20px;
}

.react-datepicker-time__input {
    font-size: 20px;
}

.react-datepicker__children-container {
    margin: 20px auto 0;
    width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/pages/EventsPage/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI;AACJ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".react-datepicker {\n    border: none;\n    border-radius: 16px;\n    padding: 20px;\n}\n\n.react-datepicker__input-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.react-datepicker__calendar-icon {\n    padding: 0 !important;\n    left: auto;\n    top: 24px !important;\n    right: 30px !important;\n}\n\n.react-datepicker__navigation {  \n    top: 22px\n}\n\n.react-datepicker__navigation--next {\n    right: 22px;\n}\n\n.react-datepicker__navigation--previous {\n    left: 22px;\n}\n\n.react-datepicker__header {\n    background-color: transparent;\n    border: none;\n}\n\n.react-datepicker__current-month {\n    font-size: 20px;\n    font-weight: 500;\n    line-height: calc(24 / 20);\n}\n\n.react-datepicker__month {\n    margin: 0;\n}\n\n.react-datepicker__day--selected {\n    background-color: #91172C;\n}\n\n.react-datepicker__day--selected:hover {\n    background-color: #91172C;\n}\n\n.react-datepicker__input-time-container {\n    margin-bottom: 20px;\n}\n\n.react-datepicker-time__caption {\n    font-size: 20px;\n}\n\n.react-datepicker-time__input {\n    font-size: 20px;\n}\n\n.react-datepicker__children-container {\n    margin: 20px auto 0;\n    width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
