// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loader__zNifK {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
}`, "",{"version":3,"sources":["webpack://./src/shared/UI/loader/loader.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EAGA,eAAA;AAFF","sourcesContent":["\n.loader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n\n  \n  font-size: 26px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `loader_loader__zNifK`
};
export default ___CSS_LOADER_EXPORT___;
