
import c from './loader.module.scss'

const Loader = () => {
  return (
    <div className={c.loader} >
      Загрузка...
    </div>
  )
}

export { Loader }