// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventsPage_events__header__83GY- {
  display: flex;
  align-items: center;
  gap: 20px;
}
.EventsPage_events__title__dpF7w {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
}
@media (max-width: 1440px) {
  .EventsPage_events__title__dpF7w {
    font-size: 32px;
  }
}
.EventsPage_events__counter__GXI7V {
  background-color: #2A2A2A;
  border-radius: 50px;
  color: #FFFFFF;
  font-family: "Saira", "sans-serif";
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-right: auto;
  padding: 12px 30px;
}
.EventsPage_events__tab__Xp\\+EX {
  margin-left: 20px;
}
.EventsPage_events__list__MCw4P > * {
  display: block;
}
.EventsPage_events__list__MCw4P > *:not(:last-child) {
  margin: 0 0 30px;
}
.EventsPage_events__map__GKer9 {
  aspect-ratio: 1.8444444444;
  background-color: #F4F4F4;
  border-radius: 50px;
  overflow: hidden;
}

.EventsPage_disableLink__0zKqL {
  pointer-events: none;
}

.EventsPage_create_event_button_wrapper__Y7Z9S {
  margin: 50px 0 100px 0;
}
.EventsPage_create_event_button_wrapper__Y7Z9S button {
  padding: 26px 60px;
}
@media (max-width: 1440px) {
  .EventsPage_create_event_button_wrapper__Y7Z9S button {
    padding: 20px 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/EventsPage/EventsPage.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAAR;AAKI;EACI,eAAA;EACA,gBAAA;EACA,gBAAA;AAHR;AAKQ;EALJ;IAMM,eAAA;EAFR;AACF;AAII;EACI,yBAAA;EACA,mBAAA;EACA,cAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAFR;AAII;EACI,iBAAA;AAFR;AAQQ;EACI,cAAA;AANZ;AAOY;EACI,gBAAA;AALhB;AAUI;EACI,0BAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;AARR;;AAYA;EACI,oBAAA;AATJ;;AAYA;EACE,sBAAA;AATF;AAWE;EACE,kBAAA;AATJ;AAWI;EAHF;IAII,kBAAA;EARJ;AACF","sourcesContent":[".events {\n    &__header {\n        display: flex;\n        align-items: center;\n        gap: 20px;\n        &:not(:last-child) {\n            // margin: 0 0 100px;\n        }\n    }\n    &__title {\n        font-size: 40px;\n        font-weight: 500;\n        line-height: calc(48 / 40);\n\n        @media (max-width: 1440px) {\n          font-size: 32px; \n        }\n    }\n    &__counter {\n        background-color: #2A2A2A;\n        border-radius: 50px;\n        color: #FFFFFF;\n        font-family: \"Saira\", \"sans-serif\";\n        font-size: 20px;\n        font-weight: 500;\n        line-height: calc(24 / 20);\n        margin-right: auto;\n        padding: 12px 30px;\n    }\n    &__tab {\n        margin-left: 20px;\n    }\n    &__body {\n\n    }\n    &__list {\n        & > * {\n            display: block;\n            &:not(:last-child) {\n                margin: 0 0 30px;\n            }\n        }\n\n    }\n    &__map {\n        aspect-ratio: calc(1660 / 900);\n        background-color: #F4F4F4;\n        border-radius: 50px;\n        overflow: hidden;\n    }\n}\n\n.disableLink {\n    pointer-events: none;\n}\n\n.create_event_button_wrapper {\n  margin: 50px 0 100px 0;\n\n  button {\n    padding: 26px 60px;\n\n    @media (max-width: 1440px) {\n      padding: 20px 30px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"events__header": `EventsPage_events__header__83GY-`,
	"events__title": `EventsPage_events__title__dpF7w`,
	"events__counter": `EventsPage_events__counter__GXI7V`,
	"events__tab": `EventsPage_events__tab__Xp+EX`,
	"events__list": `EventsPage_events__list__MCw4P`,
	"events__map": `EventsPage_events__map__GKer9`,
	"disableLink": `EventsPage_disableLink__0zKqL`,
	"create_event_button_wrapper": `EventsPage_create_event_button_wrapper__Y7Z9S`
};
export default ___CSS_LOADER_EXPORT___;
