import React, {useEffect, useState} from 'react';
import {fetchPeople} from "../../store/peopleSlice";
import {fetchFriends} from "../../store/friendsSlice";
import {useSelector} from "react-redux";
import {Swiper, SwiperSlide} from 'swiper/react';
import {RootState} from "../../store/store";
import {useAppDispatch} from "../../store/hooks";
import {Link, useParams} from "react-router-dom";
import {User} from "../../store/userSlice";
import {Navigation} from 'swiper/modules'
import styles from './FriendsPage.module.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import buttonArrow from "../../images/svg/button_arrow.svg";
import access from '../../images/svg/access.svg';
import decline from '../../images/svg/decline.svg';
import threeDots from '../../images/svg/threeDots.svg';
import deleteFriend from '../../images/svg/deleteFriend.svg';
import redFlag from '../../images/svg/redflag.svg';
import axios from "axios";
import loupeIcon from "../../images/svg/loupe.svg";

const FriendsPage = () => {

    const user = useSelector((state: RootState) => state.user.user);
    const dispatch = useAppDispatch();
    const {people} = useSelector((state: RootState) => state.people)
    const {friends} = useSelector((state: RootState) => state.friends)
    const {id} = useParams();
    const [currentUser, setCurrentUser] = useState<User>(null);
    const [yourPage, setYourPage] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortType, setSortType] = useState(0);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const toggleDropdown = (friendId) => {
        setOpenDropdown(prev => prev === friendId ? null : friendId);
    };

    useEffect(() => {
        dispatch(fetchPeople());
        dispatch(fetchFriends());
    }, [dispatch]);

    useEffect(() => {
        setCurrentUser(people.find(user => user.id.toString() === id));
    }, [currentUser, people])

    useEffect(() => {
        console.log(currentUser)
        if (user && currentUser) {
            if (user.id === currentUser.id) {
                setYourPage(true);
            }
        }
    }, [currentUser, user])

    const handleAcceptFriendship = async (event, friendship: number) => {
        event.preventDefault();
        const config: any = {}
        const token = JSON.parse(localStorage.getItem('token') || '')

        const friendsUrl = `https://api-rubin.multfilm.tatar/api/friends/accept/${friendship}`;

        await axios.post(friendsUrl, {friendshipId: friendship}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch(fetchFriends());
    }

    const handleDeleteFriendship = async (event, receiverId: number) => {
        event.preventDefault();
        const token = JSON.parse(localStorage.getItem('token') || '')

        const friendsUrl = `https://api-rubin.multfilm.tatar/api/friends/remove/${receiverId}`;

        await axios.delete(friendsUrl, {headers: {Authorization: `Bearer ${token}`}});
        dispatch(fetchFriends());
    }

    const filteredFriends = (friendList) => {
        console.log(friendList);
        return friendList.filter(friend => {
            const fullName =
                friend.sender
                    ? `${friend.sender.name} ${friend.sender.surname}`.toLowerCase()
                    : `${friend.receiver.name} ${friend.receiver.surname}`.toLowerCase();


            const isOnline = friend.sender
                ? friend.sender.online
                : friend.receiver.online;

            const nameMatch = fullName.includes(searchTerm.toLowerCase());

            if (sortType === 1) {
                return nameMatch && isOnline;
            }
            return nameMatch;
        });
    };

    const filteredCurrentUserFriends = currentUser?.friends.filter(friend => {
        const fullName = `${friend.name} ${friend.surname}`.toLowerCase();
        //@ts-ignore
        const isOnline = friend.online; // Добавляем проверку на онлайн

        const nameMatch = fullName.includes(searchTerm.toLowerCase());

        if (sortType === 1) {
            return nameMatch && isOnline; // Фильтрация только онлайн-друзей
        }

        return nameMatch; // Фильтрация всех друзей
    });

    if (!currentUser) return <p>Loading...</p>

    return (
        <div className={`page ${styles.page}`}>
            {
                yourPage && friends.awaiting.length > 0 && (
                    <>
                        <section className={`section`}>
                            <div className="section__header">
                                <h2 className="section__title">Заявки в друзья</h2>
                                <div className='section__counter'>{friends.awaiting.length}</div>
                            </div>
                            <div className={`section__body userList`} style={{padding: '70px 150px'}}>
                                <Swiper
                                    spaceBetween={20}
                                    modules={[Navigation]}
                                    navigation={{
                                        prevEl: '.button--prev',
                                        nextEl: '.button--next'
                                    }}
                                    style={{
                                        minWidth: 0,
                                        width: "100%"
                                    }}
                                    breakpoints={{
                                      1200: {
                                        slidesPerView: 4
                                      },
                                      768: {
                                        slidesPerView: 3
                                      },
                                      480: {
                                        slidesPerView: 2
                                      },
                                      360: {
                                        slidesPerView: 1
                                      }
                                    }}
                                >
                                    {[...friends.awaiting]
                                        .sort((a, b) => b.sender.rubick - a.sender.rubick)
                                        .map((friend, index) => {
                                            return (
                                                <SwiperSlide key={friend.id}>
                                                    <Link to={`/user/${friend.sender.id}`}>
                                                        <div className={styles.awaitingFriend}>
                                                            <div className={styles.awaitingFriend_avatar}>
                                                                <img src={friend.sender.avatar} alt=""/>
                                                                {currentUser.online && (
                                                                    <div style={{right: 0, bottom: '5px'}} className="big-card__avatar-status"></div>
                                                                )}
                                                            </div>
                                                            <div className={styles.awaitingFriend_name}>
                                                                {friend.sender.name} {friend.sender.surname}
                                                            </div>
                                                            <div className="levelButton" style={{marginTop: '12px'}}>
                                                                Рубиков <span>{friend.sender.rubick}</span>
                                                            </div>
                                                            <div className={styles.awaitingFriend_buttons}>
                                                                <div
                                                                    onClick={(event) => handleAcceptFriendship(event, friend.id)}
                                                                    className={`${styles.awaitingFriend_button} ${styles.awaitingFriend_buttonAccept}`}>
                                                                    <img src={access} alt=""/>
                                                                </div>
                                                                <div
                                                                    onClick={(event) => handleDeleteFriendship(event, friend.sender.id)}
                                                                    className={`${styles.awaitingFriend_button} ${styles.awaitingFriend_buttonDecline}`}>
                                                                    <img src={decline} alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </SwiperSlide>
                                            );
                                        })}
                                </Swiper>
                                <div className={styles.swiper_controls}>
                                    <button className={`button button--black button--prev ${styles.swiper_controls__more}`}
                                            type="button">
                                        <span>Предыдущие</span>
                                        <img src={buttonArrow} alt=""/>
                                    </button>
                                    <button className={`button button--black button--next ${styles.swiper_controls__more}`}
                                            type="button">
                                        <span>Показать ещё</span>
                                        <img src={buttonArrow} alt=""/>
                                    </button>
                                </div>
                            </div>
                        </section>
                    </>
                )
            }
            <div className='section__header'>
                <div className='section__title'>
                    {
                        yourPage ? 'Мои друзья' : `Друзья`
                    }
                </div>
                <div className='section__counter'>{currentUser.friends.length}</div>
            </div>
            {currentUser.friends.length > 0 && (
                <div className={`userList`}>
                    <div className={`userList_list`}>
                        {
                            yourPage ? (
                                filteredFriends(friends.friends).map(friend => (
                                    friend.sender.id !== currentUser.id ? (
                                        <Link to={`/user/${friend.sender.id}`}>
                                            <div className={styles.friendBlock}>
                                                <div className={styles.friendBlock_avatar}>
                                                    <img src={friend.sender.avatar} alt=""/>
                                                    {friend.sender.online && (
                                                        <div style={{right: '-5px', bottom: '5px', width: '16px', height: '16px'}} className="big-card__avatar-status"></div>
                                                    )}
                                                </div>
                                                <div className={styles.friendBlock_info}>
                                                    <div className={styles.friendBlock_info_name}>
                                                        {friend.sender.name} {friend.sender.surname}
                                                    </div>
                                                    <div className='levelButton' style={{width: '128px'}}>
                                                        Рубиков <span>{friend.sender.rubick}</span>
                                                    </div>
                                                </div>
                                                <div className={styles.friendBlock_buttons}>
                                                    <div className={styles.friendBlock_buttons_threeDot} onClick={(e) => {
                                                        e.preventDefault();
                                                        toggleDropdown(friend.sender.id);
                                                    }}>
                                                        <img src={threeDots} alt=""/>
                                                    </div>
                                                    {openDropdown === friend.sender.id && (
                                                        <div className={styles.dropdownMenu}>
                                                            <button onClick={(event) => handleDeleteFriendship(event, friend.sender.id)}>
                                                                <img src={deleteFriend} alt=""/>
                                                                Удалить из друзей
                                                            </button>
                                                            <button onClick={() => console.log('Пожаловаться')}>
                                                                <img src={redFlag} alt=""/>
                                                                Пожаловаться
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    ) : (
                                        <Link to={`/user/${friend.receiver.id}`}>
                                            <div className={styles.friendBlock}>
                                                <div className={styles.friendBlock_avatar}>
                                                    <img src={friend.receiver.avatar} alt=""/>
                                                    {friend.receiver.online && (
                                                        <div style={{right: '-5px', bottom: '5px', width: '16px', height: '16px'}} className="big-card__avatar-status"></div>
                                                    )}
                                                </div>
                                                <div className={styles.friendBlock_info}>
                                                    <div className={styles.friendBlock_info_name}>
                                                        {friend.receiver.name} {friend.receiver.surname}
                                                    </div>
                                                    <div className='levelButton' style={{width: '128px'}}>
                                                        Рубиков <span>{friend.receiver.rubick}</span>
                                                    </div>
                                                </div>
                                                <div className={styles.friendBlock_buttons}>
                                                    <div className={styles.friendBlock_buttons_threeDot} onClick={(e) => {
                                                        e.preventDefault();
                                                        toggleDropdown(friend.receiver.id);
                                                    }}>
                                                        <img src={threeDots} alt=""/>
                                                    </div>
                                                    {openDropdown === friend.receiver.id && (
                                                        <div className={styles.dropdownMenu}>
                                                            <button onClick={(event) => handleDeleteFriendship(event, friend.receiver.id)}>
                                                                <img src={deleteFriend} alt=""/>
                                                                Удалить из друзей
                                                            </button>
                                                            <button onClick={() => console.log('Пожаловаться')}>
                                                                <img src={redFlag} alt=""/>
                                                                Пожаловаться
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                ))
                            ) : (
                                filteredCurrentUserFriends.map(friend => (
                                    <Link to={`/user/${friend.id}`}>
                                        <div className={styles.friendBlock}>
                                            <div className={styles.friendBlock_avatar}>
                                                <img src={`https://api-rubin.multfilm.tatar/storage/${friend.avatar}`} alt=""/>
                                                {friend.online && (
                                                    <div style={{right: '-5px', bottom: '5px', width: '16px', height: '16px'}} className="big-card__avatar-status"></div>
                                                )}
                                            </div>
                                            <div className={styles.friendBlock_info}>
                                                <div className={styles.friendBlock_info_name}>
                                                    {friend.name} {friend.surname}
                                                </div>
                                                <div className='levelButton' style={{width: '128px'}}>
                                                    Рубиков <span>{friend.rubick}</span>
                                                </div>
                                            </div>
                                            <div className={styles.friendBlock_buttons}>
                                                <div className={styles.friendBlock_buttons_threeDot} onClick={(e) => {
                                                    e.preventDefault();
                                                    toggleDropdown(friend.id);
                                                }}>
                                                    <img src={threeDots} alt=""/>
                                                </div>
                                                {openDropdown === friend.id && (
                                                    <div className={styles.dropdownMenu}>
                                                        { yourPage && (
                                                            <button onClick={(event) => handleDeleteFriendship(event, friend.id)}>
                                                                <img src={deleteFriend} alt=""/>
                                                                Удалить из друзей
                                                            </button>
                                                        ) }
                                                        <button onClick={() => console.log('Пожаловаться')}>
                                                            <img src={redFlag} alt=""/>
                                                            Пожаловаться
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            )
                        }
                    </div>
                    <div className={styles.usersFilter}>
                        <form action="#" className={styles.wall__form}>
                            <button type="button">
                                <img src={loupeIcon} alt="" />
                            </button>
                            <input
                                type="text"
                                placeholder='Поиск'
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </form>
                        <div className={styles.wall__filter}>
                            {["Все друзья", "Онлайн"].map((item, index) => (
                                <button
                                    key={item + index}
                                    className={sortType === index ? `${styles.wall__filterItem} ${styles.wall__filterItemActive}` : styles.wall__filterItem}
                                    type="button"
                                    onClick={() => setSortType(index)}
                                >{item}</button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FriendsPage;