// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs_breadcrumb__KySjf {
  margin-bottom: 0 !important;
}
.Breadcrumbs_breadcrumb__KySjf li.Breadcrumbs_active__y9-gj {
  color: var(--main-color);
}

.Breadcrumbs_breadcrumbs__blNCd {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  color: #817E7E;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/Breadcrumbs.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;AAEI;EACE,wBAAA;AAAN;;AAKA;EACE,aAAA;EACA,2BAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFF","sourcesContent":[".breadcrumb {\n  margin-bottom: 0 !important;\n\n  li {\n    &.active {\n      color: var(--main-color);\n    }\n  }\n}\n\n.breadcrumbs {\n  display: flex;\n  justify-content: flex-start;\n  column-gap: 10px;\n  color: #817E7E;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `Breadcrumbs_breadcrumb__KySjf`,
	"active": `Breadcrumbs_active__y9-gj`,
	"breadcrumbs": `Breadcrumbs_breadcrumbs__blNCd`
};
export default ___CSS_LOADER_EXPORT___;
