// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contests_contests_header__6Jt0U h2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
}

@media (max-width: 1000px) {
  .Contests_image__hTPcP {
    height: max-content;
  }
}

.Contests_counter__yNV7p {
  font-size: 20px;
  line-height: 24px;
  border-radius: 50px;
  background-color: #2A2A2A;
  color: white;
  padding: 12px 30px;
  font-family: "Saira", sans-serif;
}

.Contests_contest_list__3QlKS {
  padding-top: 46px;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}

.Contests_faq__8LZsA {
  margin-top: 46px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1215686275);
  padding: 80px;
  border-radius: 50px;
}

.Contests_faq_question__U0fdF {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-bottom: 2px solid #EDEDED;
}
.Contests_faq_question__U0fdF p {
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  text-align: left;
}
.Contests_faq_question__U0fdF img {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Contests/Contests.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;;AAKE;EADF;IAEI,mBAAA;EADF;AACF;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,gCAAA;AADF;;AAIA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AADF;;AAIA;EACE,gBAAA;EACA,uDAAA;EACA,aAAA;EACA,mBAAA;AADF;;AAIA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,gCAAA;AADF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,eAAA;AAAJ","sourcesContent":[".contests_header {\n  h2 {\n    font-weight: 500;\n    font-size: 40px;\n    line-height: 48px;\n  }\n}\n\n.image {\n  @media (max-width: 1000px) {\n    height: max-content;\n  }\n}\n\n.counter {\n  font-size: 20px;\n  line-height: 24px;\n  border-radius: 50px;\n  background-color: #2A2A2A;\n  color: white;\n  padding: 12px 30px;\n  font-family: 'Saira', sans-serif;\n}\n\n.contest_list {\n  padding-top: 46px;\n  display: flex;\n  flex-direction: column;\n  row-gap: 28px;\n}\n\n.faq {\n  margin-top: 46px;\n  box-shadow: 0px 0px 8px 0px #0000001F;\n  padding: 80px;\n  border-radius: 50px;\n}\n\n.faq_question {\n  padding: 20px 0;\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n  border-bottom: 2px solid #EDEDED;\n  p {\n    font-size: 32px;\n    font-weight: 500;\n    line-height: 38px;\n    text-align: left;\n  }\n  img {\n    cursor: pointer;\n  }\n  &_hiddenAnswer {\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contests_header": `Contests_contests_header__6Jt0U`,
	"image": `Contests_image__hTPcP`,
	"counter": `Contests_counter__yNV7p`,
	"contest_list": `Contests_contest_list__3QlKS`,
	"faq": `Contests_faq__8LZsA`,
	"faq_question": `Contests_faq_question__U0fdF`
};
export default ___CSS_LOADER_EXPORT___;
