// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__SCZ0q {
  width: max-content;
  padding: 18px 60px;
  border-radius: 50px;
  background-color: var(--main-color);
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
}
.button_button__SCZ0q:active {
  transform: scale(0.99);
}
.button_button__SCZ0q:disabled {
  pointer-events: none;
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/shared/UI/button/button.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mCAAA;EACA,eAAA;EAEA,eAAA;EACA,gBAAA;EACA,mBAAA;AADF;AAGE;EACE,sBAAA;AADJ;AAIE;EACE,oBAAA;EACA,YAAA;AAFJ","sourcesContent":["\n.button {\n  width: max-content;\n  padding: 18px 60px;\n  border-radius: 50px;\n  background-color: var(--main-color);\n  cursor: pointer;\n\n  font-size: 20px;\n  font-weight: 500;\n  color: var(--white);\n\n  &:active {\n    transform: scale(0.99);\n  }\n\n  &:disabled {\n    pointer-events: none;\n    opacity: .8;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__SCZ0q`
};
export default ___CSS_LOADER_EXPORT___;
