// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  cursor: pointer;
  padding: 12px 30px;
  font-size: 20px;
  line-height: 24px;
  border-radius: 50px;
}

.Notification_acceptButton__dnfVD {
  background-color: #91172C;
  color: white;
}

.Notification_declineButton__2-Rnm {
  border: 2px solid var(--main-color);
  color: var(--main-color);
}

.Notification_notification__rQccJ {
  width: 567px;
  background-color: white;
  border-radius: 25px;
  padding: 20px;
  display: flex;
  column-gap: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.Notification_avatar__W4grE {
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.Notification_avatar__W4grE img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Notification_mainContent__z7z1s {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.Notification_mainContent__z7z1s p {
  font-size: 20px;
  line-height: 24px;
}
.Notification_mainContent__z7z1s p span {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,mCAAA;EACA,wBAAA;AACF;;AAEA;EACE,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,yCAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,iBAAA;AAEJ;AADI;EACE,gBAAA;AAGN","sourcesContent":["button {\n  cursor: pointer;\n  padding: 12px 30px;\n  font-size: 20px;\n  line-height: 24px;\n  border-radius: 50px;\n}\n\n.acceptButton {\n  background-color: #91172C;\n  color: white;\n}\n\n.declineButton {\n  border: 2px solid var(--main-color);\n  color: var(--main-color);\n}\n\n.notification {\n  width: 567px;\n  background-color: white;\n  border-radius: 25px;\n  padding: 20px;\n  display: flex;\n  column-gap: 20px;\n  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);\n}\n\n.avatar {\n  min-width: 48px;\n  max-width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  overflow: hidden;\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n\n.mainContent {\n  display: flex;\n  flex-direction: column;\n  row-gap: 20px;\n  p {\n    font-size: 20px;\n    line-height: 24px;\n    span {\n      font-weight: 500;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"acceptButton": `Notification_acceptButton__dnfVD`,
	"declineButton": `Notification_declineButton__2-Rnm`,
	"notification": `Notification_notification__rQccJ`,
	"avatar": `Notification_avatar__W4grE`,
	"mainContent": `Notification_mainContent__z7z1s`
};
export default ___CSS_LOADER_EXPORT___;
