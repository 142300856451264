// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successCreateEvent_modal_body_wrapper__4LmWQ {
  border-radius: 50px;
  max-width: 90% !important;
  z-index: 1000;
}
.successCreateEvent_modal_body_wrapper__4LmWQ > button {
  display: none !important;
}

.successCreateEvent_modal_body__rhCYa {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 60px;
  background-color: #FFFFFF;
  border-radius: 50px;
  padding: 70px;
}
.successCreateEvent_modal_body__rhCYa > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.successCreateEvent_modal_body__rhCYa b {
  font-size: 64px;
  font-weight: 600;
}
.successCreateEvent_modal_body__rhCYa p {
  font-size: 20px;
}
.successCreateEvent_modal_body__rhCYa .successCreateEvent_btns__n7PCu {
  height: 100%;
  justify-self: flex-end;
  display: grid;
  justify-content: start;
  align-items: end;
  grid-template-columns: auto auto;
  gap: 18px;
}
.successCreateEvent_modal_body__rhCYa .successCreateEvent_btns__n7PCu button:nth-child(2) {
  background-color: transparent;
  border: 2px solid var(--main-color);
  color: var(--main-color);
}
.successCreateEvent_modal_body__rhCYa img {
  max-width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SingleClubPage/tabs/success-create-event/successCreateEvent.module.scss"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,yBAAA;EACA,aAAA;AAAF;AAEE;EACE,wBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,+BAAA;EACA,SAAA;EAEA,yBAAA;EACA,mBAAA;EACA,aAAA;AAFF;AAKI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHN;AAOE;EACE,eAAA;EACA,gBAAA;AALJ;AAQE;EACE,eAAA;AANJ;AASE;EACE,YAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,gCAAA;EACA,SAAA;AAPJ;AASI;EACE,6BAAA;EACA,mCAAA;EACA,wBAAA;AAPN;AAWE;EACE,gBAAA;AATJ","sourcesContent":["\n.modal_body_wrapper {\n  border-radius: 50px;\n  max-width: 90%!important;\n  z-index: 1000;\n\n  & > button {\n    display: none!important;\n  }\n}\n\n.modal_body {\n  display: grid;\n  grid-template-columns: 1fr auto;\n  gap: 60px;\n\n  background-color: #FFFFFF;\n  border-radius: 50px;\n  padding: 70px;\n\n  & > div {\n    &:nth-child(1) {\n      display: flex;\n      flex-direction: column;\n      gap: 32px;\n    }\n  }\n\n  b {\n    font-size: 64px;\n    font-weight: 600;\n  }\n\n  p {\n    font-size: 20px;\n  }\n\n  .btns {\n    height: 100%;\n    justify-self: flex-end;\n    display: grid;\n    justify-content: start;\n    align-items: end;\n    grid-template-columns: auto auto;\n    gap: 18px;\n\n    button:nth-child(2) {\n      background-color: transparent;\n      border: 2px solid var(--main-color);\n      color: var(--main-color);\n    }\n  }\n\n  img {\n    max-width: 600px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_body_wrapper": `successCreateEvent_modal_body_wrapper__4LmWQ`,
	"modal_body": `successCreateEvent_modal_body__rhCYa`,
	"btns": `successCreateEvent_btns__n7PCu`
};
export default ___CSS_LOADER_EXPORT___;
