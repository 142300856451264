// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Notification.css */
.notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.notification p {
    margin: 0;
    font-size: 14px;
}

.notification button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chat/Notification.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;IACI,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":["/* Notification.css */\n.notification {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    background-color: #007bff;\n    color: white;\n    padding: 10px 20px;\n    border-radius: 5px;\n    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);\n}\n\n.notification p {\n    margin: 0;\n    font-size: 14px;\n}\n\n.notification button {\n    background: none;\n    border: none;\n    color: white;\n    font-size: 16px;\n    margin-left: 10px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
