// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_checkbox__dAY-S {
  display: grid;
}
.checkbox_checkbox__dAY-S label, .checkbox_checkbox__dAY-S input {
  grid-row: 1/2;
  grid-column: 1/2;
}
.checkbox_checkbox__dAY-S input {
  width: 22px;
  height: 22px;
  opacity: 0;
  cursor: pointer;
}
.checkbox_checkbox__dAY-S input:focus-visible + label {
  outline: 1px solid var(--color-text);
}
.checkbox_checkbox__dAY-S input:checked + label svg {
  opacity: 1;
  visibility: visible;
}
.checkbox_checkbox__dAY-S label {
  width: 22px;
  height: 22px;
  background: var(--color-bg);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4), inset 0px 0px 6px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--color-border);
  border-radius: 5px;
}
.checkbox_checkbox__dAY-S svg {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s ease 0s;
  transform: translate(3px, -2px);
}`, "",{"version":3,"sources":["webpack://./src/shared/UI/checkbox/checkbox.module.scss"],"names":[],"mappings":"AACA;EACC,aAAA;AAAD;AAEC;EACC,aAAA;EACA,gBAAA;AAAF;AAGC;EACC,WAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;AADF;AAGM;EACG,oCAAA;AADT;AAKC;EACC,UAAA;EACA,mBAAA;AAHF;AAMC;EACC,WAAA;EACA,YAAA;EACA,2BAAA;EACA,wFAAA;EACI,qCAAA;EACA,kBAAA;AAJN;AAOC;EACC,UAAA;EACA,kBAAA;EACI,oBAAA;EACJ,4BAAA;EACI,+BAAA;AALN","sourcesContent":["\n.checkbox {\n\tdisplay: grid;\n\n\tlabel, input {\n\t\tgrid-row: 1/2;\n\t\tgrid-column: 1/2;\n\t}\n\n\tinput {\n\t\twidth: 22px;\n\t\theight: 22px;\n\t\topacity: 0;\n\t\tcursor: pointer;\n\n      &:focus-visible + label {\n         outline: 1px solid var(--color-text);\n      }\n\t}\n\n\tinput:checked + label svg {\n\t\topacity: 1;\n\t\tvisibility: visible;\n\t}\n\n\tlabel {\n\t\twidth: 22px;\n\t\theight: 22px;\n\t\tbackground: var(--color-bg);\n\t\tbox-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .4), inset 0px 0px 6px 2px rgba(0, 0, 0, .2);\n      border: 1px solid var(--color-border);\n      border-radius: 5px;\n\t}\n\n\tsvg {\n\t\topacity: 0;\n\t\tvisibility: hidden;\n      pointer-events: none;\n\t\ttransition: all .3s ease 0s;\n      transform: translate(3px, -2px);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `checkbox_checkbox__dAY-S`
};
export default ___CSS_LOADER_EXPORT___;
