// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
}

.counter__value {
    color: var(--color-accent);
}`, "",{"version":3,"sources":["webpack://./src/components/MemoryGame/Counter/Counter.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".counter {\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 30px;\n    text-align: center;\n    text-transform: uppercase;\n}\n\n.counter__value {\n    color: var(--color-accent);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
