import styles from "./Comment.module.scss"

import DropdownMenu from "../DropdownMenu/DropdownMenu"
import likeIcon from '../../images/svg/likes.svg'
import {Client} from "../../types";
import {postFormatDate} from "../../App";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../../store/hooks";
import {commentLikeAsync, deleteCommentAsync} from "../../store/postSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import likeIconLiked from "../../images/svg/likes_red.svg";
import {User} from "../../store/userSlice";
import {fetchPeople} from "../../store/peopleSlice";
import {Link} from "react-router-dom";

interface IComment {
    id: number;
    liked_by: number[];
    text: string;
    created_at: string;
    likes_count: number;
    name: string;
    created_by: number;
    avatar: string | null;
    deleteComment: (number) => void;
}

const Comment = ({ id, liked_by, text, created_at, likes_count, name, avatar, created_by, deleteComment }: IComment) => {

    const dispatch = useAppDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [likes, setLikes] = useState(likes_count);
    const [isLiked, setIsLiked] = useState(liked_by.includes(user.user.id));
    const [isAuthor, setIsAuthor] = useState(false);

    const handleLikeClick = () => {
        if (!isLiked) {
            dispatch(commentLikeAsync({commentId: id}))
            setLikes(likes + 1);
            setIsLiked(true);
        }
    }

    const handleDeleteClick = () => {
        deleteComment(id)
        dispatch(deleteCommentAsync({commentId: id}))
    }

    const handleEditClick = () => {
        console.log('edit comment')
    }

    const goToProfile = () => {
        window.location.href = `/user/${created_by}`;
    }

    useEffect(() => {
        if (user.user.id === created_by) {
            setIsAuthor(true);
        }
    }, [])

    return (
        <div className={styles.comment}>
            <div className={styles.comment__header}>
                <div className={`${styles.comment__author} ${styles.author}`} onClick={() => goToProfile()}>
                    <div className={styles.author__avatar}>
                        <img src={avatar} alt="" />
                    </div>
                    <div className={styles.author__name}>{name}</div>
                    <div className={styles.author__date}>{postFormatDate(created_at)}</div>
                </div>
                <DropdownMenu
                  isAuthor={isAuthor}
                  comment={{ id, created_by, name }}
                  deleteClick={handleDeleteClick}
                  editClick={handleEditClick}
                />
            </div>
            <div className={styles.comment__body}>
                <p>{text}</p>
            </div>
            <div className={styles.comment__footer}>
                <div className={styles.comment__likes} onClick={handleLikeClick}>
                    <div className={styles.comment__likesIcon}>
                        <img
                            src={isLiked ? likeIconLiked : likeIcon}
                            alt=""
                        />
                    </div>
                    <span className={`${styles.comment__label} ${isLiked ? styles.comment__label_liked : ''}`}>{likes}</span>
                </div>
                {/* <button className={`${styles.comment__button} ${styles.comment__button_reply}`} type="button">Ответить</button>
                <button className={`${styles.comment__button} ${styles.comment__button_share}`} type="button">Поделиться</button> */}
            </div>
        </div>
    )
}

export default Comment
