// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfilePage_added_friend_button__lQEV1 {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--black);
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProfilePage/ProfilePage.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,8BAAA;EACA,oBAAA;AAAF","sourcesContent":["\n.added_friend_button {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  background-color: var(--black);\n  pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"added_friend_button": `ProfilePage_added_friend_button__lQEV1`
};
export default ___CSS_LOADER_EXPORT___;
