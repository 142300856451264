// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.memory {
    margin-top: 109px;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    justify-content: center;
    column-gap: 85px;
}

.memory__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 13.5px;
}`, "",{"version":3,"sources":["webpack://./src/components/MemoryGame/App.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,WAAW;AACf","sourcesContent":[".memory {\n    margin-top: 109px;\n    display: flex;\n    row-gap: 20px;\n    flex-direction: column;\n    justify-content: center;\n    column-gap: 85px;\n}\n\n.memory__cards {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    gap: 13.5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
