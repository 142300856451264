// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageTrainingCard_image_wrapper__7Iihb {
  position: relative;
  display: grid;
  border-radius: 50px;
  overflow: hidden;
}
.ImageTrainingCard_image_wrapper__7Iihb::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 128px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7));
}
.ImageTrainingCard_image_wrapper__7Iihb img {
  grid-area: 1/1;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.ImageTrainingCard_image_wrapper__7Iihb p {
  grid-area: 1/1;
  align-self: end;
  z-index: 1;
  padding: 32px;
  color: var(--white);
  font-size: 28px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ImageTrainingCard/ImageTrainingCard.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AAAF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,uEAAA;AAAJ;AAGE;EACE,cAAA;EAEA,WAAA;EACA,iBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,cAAA;EACA,eAAA;EAEA,UAAA;EACA,aAAA;EAEA,mBAAA;EACA,eAAA;AALJ","sourcesContent":["\n.image_wrapper {\n  position: relative;\n  display: grid;\n  border-radius: 50px;\n  overflow: hidden;\n\n  &::before {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n    height: 128px;\n    background: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.7));\n  }\n\n  img {\n    grid-area: 1/1;\n    \n    width: 100%;\n    aspect-ratio: 1/1;\n    object-fit: cover;\n  }\n  \n  p {\n    grid-area: 1/1;\n    align-self: end;\n\n    z-index: 1;\n    padding: 32px;\n\n    color: var(--white);\n    font-size: 28px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image_wrapper": `ImageTrainingCard_image_wrapper__7Iihb`
};
export default ___CSS_LOADER_EXPORT___;
